import { Dispatch } from 'redux';

import { AjaxEndpoint } from '@oz/shared/out/enums/AjaxEndpoint';
import { RequestStatus } from '@oz/shared/out/enums/RequestStatus';
import { DatenModellSegment } from '@oz/shared/out/enums/zulassung/DatenModellSegment';
import { Zulassungsstelle } from '@oz/shared/out/interfaces/zulassung/Zulassungsstelle';
import { ObjectSearchPath } from '@oz/shared/out/types/ObjectSearchPath';
import { isValidPlz } from '@oz/shared/out/validators/zulassung/validatePlz';
import { makeRequestFunction } from 'modules/ajax';
import { registerDataModelListener } from 'store/sideEffects/onStoreChange';
import { setZulassungstelle } from 'store/slices/zulassung/zulassungsstelleSlice';

import { ListenerCallbackItem } from '../../../../interfaces/ListenerCallbackItem';

const halterPlzPath = [
    DatenModellSegment.HALTER,
    DatenModellSegment.ADRESSE,
    DatenModellSegment.ORT,
    DatenModellSegment.PLZ,
] as ObjectSearchPath;

export default (dispatch: Dispatch): void => {
    registerDataModelListener<[ListenerCallbackItem<string>]>([halterPlzPath], async ([{ value: plz }]) => {
        if (plz && plz.length === 5) {
            if (isValidPlz(plz)) {
                const requestFunction = makeRequestFunction<string, Zulassungsstelle>(AjaxEndpoint.ZULASSUNGSSTELLE);
                const zulassungstellenResponse = await requestFunction(plz);
                if (zulassungstellenResponse.requestStatus === RequestStatus.SUCCESS) {
                    dispatch(setZulassungstelle(zulassungstellenResponse.payload));
                }
            }
        }

        dispatch(setZulassungstelle(undefined));
    });
};
