import constants from 'modules/styles/constants';

import getButtonStyles from '../../zulassung/Hub/itemsContent/util/getButtonStyles';

export const styles = {
    wrapper: {
        width: 575,
        margin: '30px auto',
        background: constants.WHITE,
        boxShadow: 'rgba(0, 0, 0, 0.2) 0px 1px 4px 0px',
        padding: 25,
        position: 'relative',
    },
    headline: {
        fontSize: 20,
        marginBottom: 20,
    },
    buttonWrapper: {
        marginTop: 20,
        display: 'flex',
        justifyContent: 'space-between',
    },
    button: {
        ...getButtonStyles(),
        marginLeft: 'auto',
    },
    secondaryButton: getButtonStyles(),
} as const;
