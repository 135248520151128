import { Dispatch } from 'redux';

import { AjaxEndpoint } from '@oz/shared/out/enums/AjaxEndpoint';
import { RequestStatus } from '@oz/shared/out/enums/RequestStatus';
import { DatenModellSegment } from '@oz/shared/out/enums/zulassung/DatenModellSegment';
import { StrassennamenRequest } from '@oz/shared/out/interfaces/zulassung/StrassennamenRequest';
import { getFromDatenModell } from '@oz/shared/out/selectors/zulassung/datenModell';
import { ObjectSearchPath } from '@oz/shared/out/types/ObjectSearchPath';
import { makeRequestFunction } from 'modules/ajax';
import { registerDataModelListener } from 'store/sideEffects/onStoreChange';
import { setOptions } from 'store/slices/zulassung/dynamicOptionsSlice';

import { ListenerCallbackItem } from '../../../../interfaces/ListenerCallbackItem';

const getStrassen = async (plz: string, ortname: string) => {
    const requestFunction = makeRequestFunction<StrassennamenRequest, string[]>(AjaxEndpoint.STRASSENNAMEN);
    const response = await requestFunction({ plz, ortname });
    if (response.requestStatus === RequestStatus.ERROR || !response.payload?.length) {
        return [];
    }
    return response.payload.map(ort => ({
        value: ort,
        label: ort,
    }));
};

export default (basePath: ObjectSearchPath, dispatch: Dispatch): void => {
    const ortPath = [...basePath, DatenModellSegment.ORT, DatenModellSegment.NAME] as ObjectSearchPath;
    const plzPath = [...basePath, DatenModellSegment.ORT, DatenModellSegment.PLZ] as ObjectSearchPath;
    const strassePath = [...basePath, DatenModellSegment.STRASSE] as ObjectSearchPath;

    registerDataModelListener<[ListenerCallbackItem<string>]>([ortPath], async ([{ value: ort }], { state }) => {
        if (ort) {
            const plz = getFromDatenModell<string>(plzPath)(state);
            if (plz) {
                const options = await getStrassen(plz, ort);

                dispatch(setOptions({ path: strassePath, options }));
                return;
            }
        }
        dispatch(setOptions({ path: strassePath, options: [] }));
    });
};
