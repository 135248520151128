import { CheckUIThemeContextProvider } from '@kfz/check-ui/context/ThemeContext';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { ApplicationError as ApplicationErrorEnum } from '@oz/shared/out/enums/ApplicationError';
import { getDeviceType } from 'modules/applicationConfig';
import registerAppSideEffects from 'store/sideEffects/zulassung/registerAppSideEffects';
import { setApplicationError } from 'store/slices/applicationErrorSlice';

import ApplicationError from './components/common/ApplicationError/ApplicationError';
import AppRouter from './components/common/AppRouter/AppRouter';
import { CHANNEL_NAME } from './constants/worker';
import { useLocationListener } from './hooks/useLocationListener';
import { WorkerMessage } from './workers/WorkerMessage';

const App = () => {
    useLocationListener();
    const dispatch = useDispatch();

    useEffect(() => {
        registerAppSideEffects(dispatch);
    }, [dispatch]);

    // refresh the page in case a chunk cannot be loaded
    useEffect(() => {
        try {
            const channel = new BroadcastChannel(CHANNEL_NAME);
            channel.onmessage = (e: MessageEvent) => {
                if (e?.data?.errorType === WorkerMessage.ASSET_LOAD_ERROR) {
                    dispatch(setApplicationError(ApplicationErrorEnum.CHUNK_LOAD_ERROR));
                }
            };
        } catch (e) {
            // do nothing
        }
    }, [dispatch]);

    return (
        <ApplicationError>
            <CheckUIThemeContextProvider device={getDeviceType()} theme={{}}>
                <AppRouter />
            </CheckUIThemeContextProvider>
        </ApplicationError>
    );
};

export default App;
