import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Zulassungsstelle } from '@oz/shared/out/interfaces/zulassung/Zulassungsstelle';

// Slice
const slice = createSlice({
    name: 'zulassungsstelle',
    initialState: {},
    reducers: {
        // @ts-ignore
        setZulassungstelle: (state: Zulassungstelle, action: PayloadAction<Zulassungstelle>): Zulassungsstelle => {
            return action.payload;
        },
    },
});
export default slice.reducer;

// Actions
export const { setZulassungstelle } = slice.actions;
