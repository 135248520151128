import { FormRow } from '@kfz/check-ui/elements/FormRow';
import { InputValidity } from '@kfz/check-ui/types';
import React, { FocusEvent, useCallback, useEffect, useState } from 'react';

import normalizePhoneNumber from '@oz/shared/out/modules/zulassung/phoneNumber/normalizePhoneNumber';
import { validateEmail } from '@oz/shared/out/validators/validateValue';
import validateTelefonnummer from '@oz/shared/out/validators/zulassung/validateTelefonnummer';
import { isApp } from 'modules/applicationConfig';
import { initializeLoginLayer, openLoginLayer } from 'modules/loginLayer';
import { getClasses } from 'modules/styles/utils';
import hideSpinner from 'modules/utils/hideSpinner';

import LoginFieldHelpText from '../../helpTexts/LoginFieldHelpText';
import CheckList from '../CheckList/CheckList';
import OzWithHelpText from '../hoc/OzWithHelpText/OzWithHelpText';
import OneColLayoutForm from '../OneColLayoutForm/OneColLayoutForm';
import SectionHeadline from '../SectionHeadline/SectionHeadline';
import TextInput from '../TextInput/TextInput.device';

import { styles } from './styles.device';

const cssClasses = getClasses<typeof styles>(styles);

const RowWithHelpText = OzWithHelpText(FormRow);

const defaultValidation = { state: InputValidity.INCOMPLETE, message: '' };
const validValidation = { state: InputValidity.VALID, message: '' };
const invalidValidation = { state: InputValidity.INVALID, message: '' };

const getCurrentUrlWithoutProtocol = () => {
    const curr = window.location.href;
    return curr.substring(curr.indexOf('//') + 2);
};

const getFieldValid = (val: string): boolean[] => {
    const emailValidation = validateEmail(val);
    const phoneValidation = validateTelefonnummer(normalizePhoneNumber(val));
    return [!emailValidation, !phoneValidation];
};

const isFieldValid = (val: string) => getFieldValid(val).some(valid => valid);

const CheckListWrapper = () => {
    return (
        <div className={cssClasses.infoListWrapper}>
            <h3 className={cssClasses.infoListHeadline}>Für die Online-Zulassung benötigen Sie Folgendes:</h3>
            <CheckList
                items={[
                    'Fahrzeugschein und -brief, jeweils mit aufgedruckten Sicherheitscodes',
                    'Aktuelle Kennzeichenschilder, falls Ihr Fahrzeug aktuell angemeldet ist',
                    'Gültiger Ausweis & Handynummer',
                ]}
            />
        </div>
    );
};

const validationMessage = 'Bitte geben Sie eine gültige E-Mail-Adresse oder Telefonnummer ein.';

export default () => {
    const [fieldValue, setFieldValue] = useState('');
    const [fieldValidation, setFieldValidation] = useState(defaultValidation);
    const [fieldErrorText, setFieldErrorText] = useState('');

    useEffect(() => {
        hideSpinner();
    });

    const onBlur = useCallback((event: FocusEvent<HTMLInputElement>) => {
        const { value } = event.target;
        if (value) {
            if (!isFieldValid(value)) {
                setFieldValidation(invalidValidation);
                setFieldErrorText(validationMessage);
            } else {
                setFieldValidation(validValidation);
                setFieldErrorText('');
            }
        } else {
            setFieldValidation(defaultValidation);
            setFieldErrorText('');
        }
    }, []);

    const onInput = useCallback((v: string | undefined) => {
        setFieldValue(v || '');
        setFieldErrorText('');
        if (isFieldValid(v || '')) {
            setFieldValidation(validValidation);
        } else {
            setFieldValidation(defaultValidation);
        }
    }, []);

    const onSubmit = useCallback(() => {
        if (!fieldValue) {
            setFieldValidation(invalidValidation);
            setFieldErrorText(validationMessage);
            return;
        }
        if (fieldValue && !fieldErrorText) {
            if (isApp()) {
                window.location.href = `c24-app-login://${getCurrentUrlWithoutProtocol()}&username=${fieldValue}&allowRelogin=true`;
                return;
            }
            const [emailValid] = getFieldValid(fieldValue);
            const valueToSend = emailValid ? fieldValue : normalizePhoneNumber(fieldValue);
            initializeLoginLayer(encodeURIComponent(valueToSend), !emailValid);
            openLoginLayer();
        }
    }, [fieldErrorText, fieldValue]);

    return (
        <OneColLayoutForm buttonAction={onSubmit} buttonText="weiter" loading={false} belowButton={CheckListWrapper}>
            <SectionHeadline>Willkommen bei der Online-Zulassung</SectionHeadline>
            <p className={cssClasses.text}>
                Die Online-Zulassung ist ein exklusives Angebot für unsere Kunden. Bitte melden Sie sich an, um die
                Online-Zulassung nutzen zu können.
            </p>

            <RowWithHelpText helpText={LoginFieldHelpText} name="login_email">
                <TextInput
                    name="login_email"
                    valid={fieldValidation}
                    label="E-Mail-Adresse oder Telefonnummer"
                    disabled={false}
                    config={{ maxLength: 81, inputType: 'email', onBlur }}
                    value={fieldValue}
                    setValue={onInput}
                />
                {fieldErrorText && <div className={cssClasses.error}>{fieldErrorText}</div>}
            </RowWithHelpText>
        </OneColLayoutForm>
    );
};
