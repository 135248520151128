import { AjaxEndpoint } from '@oz/shared/out/enums/AjaxEndpoint';
import { RequestStatus } from '@oz/shared/out/enums/RequestStatus';
import { OrtnamenRequest } from '@oz/shared/out/interfaces/zulassung/OrtnamenRequest';
import { makeRequestFunction } from 'modules/ajax';

export default async (plz: string) => {
    const requestFunction = makeRequestFunction<OrtnamenRequest, string[]>(AjaxEndpoint.ORTNAMEN);
    const response = await requestFunction({ plz });
    if (response.requestStatus === RequestStatus.ERROR || !response.payload?.length) {
        return [];
    }
    return response.payload.map(ort => ({
        value: ort,
        label: ort,
    }));
};
